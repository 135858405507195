<template>
    <header class="bg-gold-3 dark:bg-dark-4 sticky top-0 z-50">
        <nav class="mx-auto max-w-7xl py-4" aria-label="Top">
            <div class="flex w-full items-center justify-between lg:border-none">
                <div class="flex items-center w-full justify-center lg:justify-between">
                    <a href="#" class="flex flex-col items-center">
                        <img class="h-10 w-auto" src="@/assets/logo.png" alt="" />
                        <span class="cursor-pointer text-base font-bold site-title">Bespoke Luxury Rentals</span>
                    </a>
                    <div class="ml-10 hidden space-x-8 lg:block">
                        <a @click="scrollTo('CarouselWrapper')" class="cursor-pointer text-base font-bold site-title">Home</a>
                        <router-link :to="{ path: '/cars' }" class="text-base font-bold site-title">
                            Cars
                        </router-link>
                        <a @click="scrollTo('contact')" class="cursor-pointer text-base font-bold site-title">Contact Us</a>
                            <!-- <router-link v-for="link in navigation" :key="link.name" :to="{ path: link.href }" class="text-base font-bold site-title">
                                {{link.name}}
                            </router-link> -->
                    </div>
                    <div id="contactWrapper" class="flex-col items-start hidden lg:flex">
                        <div class="flex justify-center items-center gap-2">
                            <font-awesome-icon icon="fa-solid fa-phone" size="md" class="text-gold"/>
                            <p class="cursor-pointer text-base font-bold site-title">07111 111 111</p>
                        </div>
                        <div class="flex justify-center items-center gap-2">
                            <font-awesome-icon icon="fa-solid fa-envelope" size="md" class="text-gold"/>
                            <p class="cursor-pointer text-base font-bold site-title">example@example.com</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap justify-center space-x-6 py-4 lg:hidden">
                <ul class="flex gap-5">
                    <li v-for="link in navigation" :key="link.name">
                        <router-link :to="{ name: link.name }" class="site-title">
                            {{link.name}}
                        </router-link>
                    </li>
                </ul>
            </div>

            <div id="contactWrapper" class="lg:hidden">
                <div class="flex justify-center items-center gap-2">
                    <font-awesome-icon icon="fa-solid fa-phone" size="md" class="text-gold"/>
                    <p class="cursor-pointer text-base font-bold site-title">07111 111 111</p>
                </div>
                <div class="flex justify-center items-center gap-2">
                    <font-awesome-icon icon="fa-solid fa-envelope" size="md" class="text-gold"/>
                    <p class="cursor-pointer text-base font-bold site-title">example@example.com</p>
                </div>
            </div>

        </nav>
    </header>
</template>

<script setup>
const navigation = [
{ name: 'Home', href: '/' },
{ name: 'Cars', href: '/cars' },
{ name: 'Contact Us', href: '/#contact'}
    
]
</script>

<style scoped>

</style>