export default {
    data () {
        return {}
    },
    methods:{
        scrollTo(elmID) {
            // If the element is on the page
            if(document.getElementById(elmID)) {
                // Scroll to the element
                document.getElementById(elmID).scrollIntoView({
                    behavior: "smooth", block: "start", inline: "nearest"
                });
            } else {
                // Otherwise, go to the homepage
                this.$router.push({ name: 'Home' }).then(function() {
                    // And scroll to the element
                    document.getElementById(elmID).scrollIntoView({
                        behavior: "smooth", block: "start", inline: "nearest"
                    });
                });
            }
        },
        scrollToCar(elmID) {
            // If the element is on the page
            if(document.getElementById(elmID)) {
                // Scroll to the element
                document.getElementById(elmID).scrollIntoView({
                    behavior: "smooth", block: "start", inline: "nearest"
                });
            } else {
                // Otherwise, go to the homepage
                this.$router.push({ name: 'Cars' }).then(function() {
                    // And scroll to the element
                    document.getElementById(elmID).scrollIntoView({
                        behavior: "smooth", block: "start", inline: "nearest"
                    });
                });
            }
        },
    }
}