<template>
  <div class="home">
    <div id="CarouselWrapper">
      <Carousel />
    </div>
    
    <!-- Title and Description blocks -->
    <div class="flex flex-col py-24 items-center bg-white dark:bg-dark-1">
      <h1 class="text-3xl text-center font-bold sm:text-4xl site-title">Bespoke Luxury Rentals</h1>
      <h3 class="text-1xl text-center text-gray-700 dark:text-gray-300 sm:text-2xl pt-10 max-w-1/2">
        Here at Bespoke Luxury Rentals, we specialise in providing the opportunity for everyone and anyone to experience a supercar. 
        From sleek sports cars to powerful luxury vehicles, we have a wide selection of supercars to choose from.</h3>
      <h3 class="text-1xl text-center text-gray-700 dark:text-gray-300 sm:text-2xl pt-10 max-w-1/2">
        Each of our cars is carefully maintained and equipped with state-of-the-art features to ensure a smooth and enjoyable ride.</h3>
      <h3 class="text-1xl text-center text-gray-700 dark:text-gray-300 sm:text-2xl pt-10 max-w-1/2">
        Whether you want to turn heads on the open road or make a statement at a special event, Bespoke Luxury Rentals has the perfect vehicle for you</h3>
    </div>

    <!-- Cars Block -->
    <div class="flex flex-col py-24 items-center bg-white dark:bg-dark-1">
      <h1 class="text-3xl text-center font-bold site-title sm:text-4xl">Supercars</h1>
      <h3 class="text-1xl text-center text-gray-700 dark:text-gray-300 sm:text-2xl pt-10 max-w-1/2">Our fleet of supercars that will meet any need you desire</h3>
      <div class="w-7/10 pt-10">
        <ul class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        <li v-for="car in cars" :key="car.source" class="relative">
            <div class="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-jet">
                <img :src="car.source" alt="" class="object-cover" />
            </div>
            <ul class="text-gray-300 text-left">
              <li v-if="car.make" class="mt-2 text-m font-medium site-title">{{car.make}} {{car.model}}</li>
              <li v-if="car.colour" class="text-sm font-medium text-gray-500">{{car.colour}}</li>
              <li v-if="car.fuelType" class="text-sm font-medium text-gray-500">{{car.fuelType}}</li>
            </ul>
        </li>
        </ul>

      </div>
      <div class="flex space-x-2 justify-center pt-7">
        <router-link :to="{ path: '/cars' }">
        <button type="button" class="inline-block px-7 py-3 bg-gold text-white dark:text-jet font-bold text-sm leading-snug uppercase rounded shadow-md hover:bg-gold-2 hover:shadow-lg focus:bg-gold-2 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gold-2 active:shadow-lg transition duration-150 ease-in-out">VIEW ALL CARS</button>
        </router-link>
      </div>
    </div>

    <!-- Services Block -->
    <div class="flex flex-col py-24 items-center bg-white dark:bg-dark-1">
      <h1 class="text-3xl text-center font-bold site-title sm:text-4xl">Services that we offer</h1>
      <h3 class="text-1xl text-center text-gray-700 dark:text-gray-300 sm:text-2xl pt-10 max-w-1/2">Whether it's short or long term hire, or a Chauffeur you're needing - we've got you covered</h3>
      <div class="w-7/10 pt-20 grid grid-cols-1 grid-rows-3 lg:grid-cols-3 lg:grid-rows-1 gap-10">
        <div class="text-gold">
          <font-awesome-icon icon="fa-solid fa-car" size="6x"/>
          <h1 class="pt-5 font-bold text-gray-700 dark:text-gray-300">Luxury Supercar Hire</h1>
          <p class="pt-5 text-gray-700 dark:text-gray-300">Our supercar hire service allows you to experience the thrill of driving some of the most exotic and high-performance vehicles on the market</p>
        </div>

        <div class="text-gold">
          <font-awesome-icon icon="fa-solid fa-user-tie" size="6x"/>
          <h1 class="pt-5 font-bold text-gray-700 dark:text-gray-300">Chauffeur Service</h1>
          <p class="pt-5 text-gray-700 dark:text-gray-300">Each of our Chauffeurs is specially trained and qualified to give you the smoothest and most professional experience possible</p>
        </div>

        <div class="text-gold">
          <font-awesome-icon icon="fa-solid fa-champagne-glasses" size="6x"/>
          <h1 class="pt-5 font-bold text-gray-700 dark:text-gray-300">Weddings and Parties</h1>
          <p class="pt-5 text-gray-700 dark:text-gray-300">Our supercars will be sure to turn a head (or camera) at any event that you may need</p>
        </div>

      </div>
    </div>

    <!-- Brands Block -->
    <div class="flex flex-col py-24 items-center bg-gray-200 dark:bg-jet">
      <h1 class="text-3xl text-center font-bold site-title sm:text-4xl">Luxury Marques</h1>
      <h3 class="text-1xl text-center text-gray-700 dark:text-gray-300 sm:text-2xl pt-10 max-w-1/2">The vehicles that we have available come from most iconic luxury car marques that the world has to offer</h3>
      <div class="w-7/10 pt-20 grid grid-cols-3 grid-rows-2 gap-10">
        <div class="flex items-center justify-center">
          <img src="@/assets/car-marques/rolls-royce-logo.png" width="180" height="240">
        </div>

        <div class="flex items-center justify-center">
          <img src="@/assets/car-marques/audi-logo.png" width="180" height="240">
        </div>

        <div class="flex items-center justify-center">
          <img src="@/assets/car-marques/bmw-logo.png" width="180" height="240">
        </div>

        <div class="flex items-center justify-center">
          <img src="@/assets/car-marques/mercedes-logo.png" width="180" height="240">
        </div>

        <div class="flex items-center justify-center">
          <img src="@/assets/car-marques/lamborghini-logo.png" width="180" height="240">
        </div>

        <div class="flex items-center justify-center">
          <img src="@/assets/car-marques/range-rover-logo.png" width="180" height="240">
        </div>

      </div>
    </div>

    <div id="ContactWrapper">
    <ContactForm />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContactForm from '@/components/ContactForm.vue'
import Carousel from '@/components/CarouselComponent.vue'


export default {
  name: 'HomeView',
  data() {
    return {
    cars: [
              {
                  title: 'IMG_4985.HEIC',
                  size: '3.9 MB',
                  source:
                      'https://i.imgur.com/15XbDAX.png',
                  make: 'Lamborghini',
                  model: 'Aventador SVJ',
                  colour: 'Green',
                  fuelType: 'Petrol',
              },
              {
                  title: 'IMG_4985.HEIC',
                  size: '3.9 MB',
                  source:
                      'https://i.imgur.com/15XbDAX.png',
                  make: 'Lamborghini',
                  model: 'Urus',
                  colour: 'Grey',
                  fuelType: 'Petrol',
              },
              {
                  title: 'IMG_4985.HEIC',
                  size: '3.9 MB',
                  source:
                      'https://i.imgur.com/15XbDAX.png',
                  make: 'Lamborghini',
                  model: 'Huracán Performante',
                  colour: 'Green',
                  fuelType: 'Petrol',    
              },
              {
                  title: 'IMG_4985.HEIC',
                  size: '3.9 MB',
                  source:
                      'https://i.imgur.com/15XbDAX.png',
                  make: 'Lamborghini',
                  model: 'Huracán EVO',
                  colour: 'Green',
                  fuelType: 'Petrol',
              },
              {
                  title: 'IMG_4985.HEIC',
                  size: '3.9 MB',
                  source:
                      'https://i.imgur.com/15XbDAX.png',
                  make: 'Rolls Royce',
                  model: 'Cullinan',
                  colour: 'Black',
                  fuelType: 'Petrol',
              },
              {
                  title: 'IMG_4985.HEIC',
                  size: '3.9 MB',
                  source:
                      'https://i.imgur.com/15XbDAX.png',
                  make: 'Rolls Royce',
                  model: 'Ghost',
                  colour: 'White',
                  fuelType: 'Petrol',
              },
              {
                  title: 'IMG_4985.HEIC',
                  size: '3.9 MB',
                  source:
                      'https://i.imgur.com/15XbDAX.png',
                  make: 'Rolls Royce',
                  model: 'Ghost',
                  colour: 'Black',
                  fuelType: 'Petrol',
              },
              {
                  title: 'IMG_4985.HEIC',
                  size: '3.9 MB',
                  source:
                      'https://i.imgur.com/15XbDAX.png',
                  make: 'Mercedes AMG',
                  model: 'CLA 45 S',
                  colour: 'White',
                  fuelType: 'Petrol',
              },
            ]
        }
  },
  components: {
    ContactForm,
    Carousel,
  }
}
</script>
